import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css'; // Import the CSS file
import worker from "../Graphics/Worker.webp"

export const ContactForm = () => {
  const form = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false); // State variable to track if message is sent

  const clearFields = () => {
    setName('');
    setEmail('');
    setMessage('');
    setTopic('');
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_r3xfha4', 'template_x190l99', form.current, {
        publicKey: '5Flg1um9nd0AtyhMJ',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          clearFields(); // Clear input fields after successful submission
          setMessageSent(true); // Set messageSent to true after successful submission
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="contact-form-container" id="kontakt">
      <div className="contact-image-container">
                    <img src={worker} alt="Office Worker" className="worker-image" />
                </div>
     
      <div className="contact-form-box">
        <form ref={form} onSubmit={sendEmail}>
        <h2>Kontakta oss</h2>
          <label>Namn</label>
          <input type="text" name="user_name" value={name} onChange={(e) => setName(e.target.value)} />
          <label>Email</label>
          <input type="email" name="user_email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label>Ämne</label>
          <input type="topic" name="user_topic" value={topic} onChange={(e) => setTopic(e.target.value)} />
          <label>Meddelande</label>
          <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
          <input type="submit" value="Skicka meddelande" />
        </form>
      </div>
      {messageSent && <p>Meddelande skickat</p>}
    </div>
  );
};
