// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth for Firebase Authentication
import { getFirestore } from "firebase/firestore"; // Import getFirestore for Firestore Database

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAEUWGT8PRjMtXseM57AJmVf8uF84SJzNw",
  authDomain: "connectwork-f3770.firebaseapp.com",
  projectId: "connectwork-f3770",
  storageBucket: "connectwork-f3770.appspot.com",
  messagingSenderId: "498684977989",
  appId: "1:498684977989:web:41e7cd19d38adb1499542c",
  measurementId: "G-P0Z6TXCWN4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Authentication
const database = getFirestore(app); // Initialize Firestore Database

export { auth, database }; // Export both auth and db objects
export default app;
