import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'; // Import the signOut method
import './LoginPage.css';
import image from '../Graphics/login.webp'

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in');
      onLogin();
      setLoggedInUser(email);
    } catch (error) {
      console.error('Error signing in:', error.message);
      setError(error.message);
    }
    setEmail('');
    setPassword('');
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth) // Call signOut method from Firebase auth
      .then(() => {
        console.log('User logged out');
        setLoggedInUser(null);
      })
      .catch((error) => {
        console.error('Error signing out:', error.message);
      });
  };

  return (
    <div className="login-container">
      {loggedInUser ? (
        <div>
          <h2>Välkommen, {loggedInUser}!</h2>
          <button id="LoginButton" onClick={handleLogout}>Logga ut</button> {/* Call handleLogout on click */}
        </div>
      ) : (
        <div>
          <h2>Logga in</h2>
          <img src={image} alt="Login" className="LoginImage" />

          <form id="login-form" onSubmit={handleLogin}>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email-inputLogin"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password">Lösenord:</label>
              <input
                type="password"
                id="password-inputLogin"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" id="buttonLogin">Logga in</button>
            {error && <div className="error-message">{error}</div>}
          </form>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
