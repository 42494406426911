import React from 'react';
import './ForJobSeekers.css';
import image from '../Graphics/Jobseekers.jpg'; // Import your image
import { Link } from 'react-router-dom';

function ForJobSeekers({ setShowJobsPage }) {
  const handleJobsClick = () => {
    setShowJobsPage(true);
  };

  return (
    <div>
      <div className="JobSeekerimageContainer" id="jobbsokande">
        <img src={image} alt="Header" className="headerImage" />
        <div className="overlayBox">
          <h2 className="boxHeader">Jobbsökande? Så söker du jobb</h2>
          <p className="boxParagraph">Jobbsökandet börjar ofta bland våra lediga jobb där du enkelt hitta ett jobb som matchar just dig.</p>
          <button className="boxButton" onClick={handleJobsClick}>Lediga Jobb</button>
        </div>
      </div>
      <div className="jobSeekersContainer">
        <h1 className="jobSeekersHeading">För jobbsökande</h1>
        <div className="jobSeekersSection">
          <h2 className="jobSeekersTitle">1. Skapa profil med CV</h2>
          <p className="jobSeekersText">När du hittat ett eller flera intressanta jobb är det dags att skicka in din ansökan. Det gör du genom att gå in på lediga jobb. </p>
        </div>
        <div className="jobSeekersSection">
          <h2 className="jobSeekersTitle">2. Komplettera din ansökan</h2>
          <p className="jobSeekersText">Komplettera ditt CV med ett personligt brev som kan vara intressant för tjänsten du söker.</p>
        </div>
        <div className="jobSeekersSection">
          <h2 className="jobSeekersTitle">3. Skicka in ansökan</h2>
          <p className="jobSeekersText">Vi rekommenderar alltid att söka jobbet så snart som möjligt eftersom vi går igenom ansökningarna löpande i den ordning de kommer in. Sen är det bara att hålla tummarna för att du blir kallad på intervju!</p>
        </div>
      </div>
    </div>
  );
}

export default ForJobSeekers;
