import React from 'react';
import './Homepage.css';
import image from '../Graphics/illustration-businessman-magnifying-glass-laptop.jpg'; // Import your image

function Homepage() {
    return (
        <div className="homepageContainer" id="home">
            <div className="homepageContent">
                <div className="homepageText">
                    <h1 className="homepageTitle">Välkommen till ConnectWork</h1>
                    <p className="homepageDescription">ConnectWork är plattformen där arbetssökande och arbetsgivare kopplar samman. Hitta ditt drömjobb eller den perfekta kandidaten idag.</p>
                    <a href="#om">
                        <button className="homepageButton">Läs mer</button>
                    </a>
                </div>
                <div className="homepageImageContainer">
                    <img src={image} alt="Homepage" className="homepageImage" />
                </div>
            </div>
        </div>
    );
}

export default Homepage;
