import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ApplyJobForm.css'; // Import the CSS file

export const ApplyJobForm = ({ jobTitle, jobId, onClose }) => {
    const form = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [file1, setFile1] = useState(null); // State variable for first file input
    const [file2, setFile2] = useState(null); // State variable for second file input
    const [messageSent, setMessageSent] = useState(false); // State variable to track if message is sent

    const clearFields = () => {
        setName('');
        setEmail('');
        setMessage('');
        setFile1(null);
        setFile2(null);
    };

    const sendApplication = (e) => {
        e.preventDefault();
    
        const formData = new FormData(form.current);
        formData.append('file1', file1); // Append first file to form data
        formData.append('file2', file2); // Append second file to form data
    
        emailjs
          .sendForm('service_r3xfha4', 'template_x190l99', form.current, {
            publicKey: '5Flg1um9nd0AtyhMJ',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              clearFields(); // Clear input fields after successful submission
              setMessageSent(true); // Set messageSent to true after successful submission
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
    };
    

    return (
        <div className="modal sticky">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Ansök För: {jobTitle}</h2>
                <form ref={form} onSubmit={sendApplication} encType="multipart/form-data">
                    <div className="form-group inline">
                        <label htmlFor="name-input">Namn</label>
                        <input id="name-inputApply" type="text" name="user_name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-group inline">
                        <label htmlFor="email-input">Email</label>
                        <input id="email-inputApply" type="email" name="user_email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="message-textarea">Meddelande:</label>
                        <textarea id="message-textareaApply" className="sticky-textarea" name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                    
                    <input type="submit" id="submitApply" value="Apply" />
                </form>
                {messageSent && <p>Ansökan Skickad!</p>}
            </div>
        </div>
    );
};




/*
return (
    <div className="modal sticky">
        <div className="modal-content">
            <span className="close" onClick={onClose}>&times;</span>
            <h2>Ansök För: {jobTitle}</h2>
            <form ref={form} onSubmit={sendApplication} encType="multipart/form-data">
                <div className="form-group inline">
                    <label htmlFor="name-input">Namn</label>
                    <input id="name-inputApply" type="text" name="user_name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group inline">
                    <label htmlFor="email-input">Email</label>
                    <input id="email-inputApply" type="email" name="user_email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                
                <div className="form-group">
                    <label htmlFor="file1-input">Bifoga CV:</label>
                    <input id="file1-inputApply" type="file" name="file1" onChange={(e) => setFile1(e.target.files[0])} />
                </div>
                <div className="form-group">
                    <label htmlFor="file2-input">Bifoga personligt brev:</label>
                    <input id="file2-inputApply" type="file" name="file2" onChange={(e) => setFile2(e.target.files[0])} />
                </div>
                <div className="form-group">
                    <label htmlFor="message-textarea">Meddelande:</label>
                    <textarea id="message-textareaApply" className="sticky-textarea" name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                </div>
                
                <input type="submit" id="submitApply" value="Apply" />
            </form>
            {messageSent && <p>Ansökan Skickad!</p>}
        </div>
    </div>
);
};
*/