import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Homepage';
import About from './components/about';
import LoginPage from './components/LoginPage';
import Jobs from './components/jobs';
import { ContactForm } from './components/ContactForm';
import Footer from './components/Footer';
import ForEmployeers from './components/ForEmployeers';
import ForJobSeekers from './components/ForJobSeekers';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import firebaseApp from './Backend/firebaseConfig';
import { getAuth, signOut } from 'firebase/auth';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [showJobsPage, setShowJobsPage] = useState(false);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User logged out');
        setLoggedIn(false);
      })
      .catch((error) => {
        console.error('Error signing out:', error.message);
      });
  };

  return (
    <Router>
      <div className="App">
        <Navbar loggedIn={loggedIn} handleLogout={handleLogout} setShowLoginPage={setShowLoginPage} setShowJobsPage={setShowJobsPage} />
        <div className='page-container'>
          <div className='content-wrapper'>
            <div className='content'>
              {!showLoginPage && !showJobsPage && (
                <>
                  <Home />
                  <ForJobSeekers setShowJobsPage={setShowJobsPage} />
                  <ForEmployeers />
                  <About />
                  <ContactForm />
                 
                 
                </>
              )}
              {showLoginPage && <LoginPage onLogin={() => setLoggedIn(true)} />}
              {showJobsPage && <Jobs loggedIn={loggedIn} />}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
