import React from 'react';
import './ForEmplooyers.css';
import mainImage from '../Graphics/about-image.png'; // Import main image
import smallerImage from '../Graphics/Jobseekers.jpg'; // Import smaller image
import { Link } from 'react-router-dom';

const ForEmployers = () => {
    return (
        <div id="ForEmplooyerheaderContainer">
            <div className="ForJobSeekersoverlayBox">
                <img src={smallerImage} alt="Smaller" className="smallerImage" />
                <div className="textContent">
                    <h2 id="ForEmplooyerheaderText" className="ForEmplooyerboxHeader">För arbetsgivare & kunder</h2>
                    <p id="ForEmplooyerboxText" className="ForEmplooyerboxParagraph">Människorna är varje verksamhets viktigaste tillgång, och för att ditt företag ska hålla sig konkurrenskraftigt krävs det att du har helt rätt medarbetare. Oavsett vilket personalbehov du har hittar vi en lösning som passar just dig och din verksamhet.
</p>
                    <a href="#om">

                    <button id="ForEmplooyerheaderButton" className="ForEmplooyerboxButton">Kontakta oss</button>
                    </a> 
                </div>
            </div>
            <div id="forEmployersContainer">
              
                <div id="forEmployersContent">
                    <div id="employerSection1" className="employerSection">
                        <h3 id="employerTitle1" className="employerTitle">Rekrytering</h3>
                        <p id="employerText1" className="employerText">
                        Det kan vara tidskrävande och svårt för företag att själva sköta rekryteringen av nya medarbetare. Våra erfarna rekryterare hjälper er att hitta rätt kandidater, som matchar era behov och passar in i er organisation.
                        </p>
                    </div>
                    <div id="employerSection2" className="employerSection">
                        <h3 id="employerTitle2" className="employerTitle">Säkra kvaliteten på er rekrytering</h3>
                        <p id="employerText2" className="employerText">
                        Rekrytering är en avgörande process som kan bli kostsam om den inte görs rätt. Vi är specialister på rekrytering och hjälper er att göra träffsäkra rekryteringar. Våra inarbetade rutiner säkerställer kvalitet och effektivitet i varje steg.
                        </p>
                    </div>
                    <div id="employerSection3" className="employerSection">
                        <h3 id="employerTitle3" className="employerTitle">Hitta kandidater med rätt kompetens</h3>
                        <p id="employerText3" className="employerText">
                        Konkurrensen om kandidaterna är stor och många företag upplever att det är svårt att själva hitta den kompetens de söker. Vi hjälper er att sticka ut i mängden och ger råd om kanaler och kommunikation.
Vi använder målgruppsstyrd annonsering på utvalda plattformar för att marknadsföra ert jobberbjudande och hitta matchande kandidater. Vi nyttjar vårt breda kontaktnät och söker både bland de som aktivt letar jobb och passiva kandidater som har rätt profil.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForEmployers;
