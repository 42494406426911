import React from 'react';
import './about.css';
import aboutImage from '../Graphics/About.webp';

const About = () => {
    return (
        <div className="about-container" id="om">
            <div className="about-content">
                <div className="about-text-container">
                    <div className="about-text">
                        <h1 id="WelcomeTItle">Välkommen till ConnectWork</h1>
                        <p id="about-description">
                        Vi på Connectwork är dedikerade till att möta alla dina rekryteringsbehov. Vår vision är att 'förändra människors liv genom att skapa möjligheter att nå sin fulla potential'. Vi följer en ärlig och professionell metodik för att identifiera de bästa individerna för alla våra kunder. Utöver detta erbjuder vi även kandidaterna vägledning, rådgivning, stöd och ett brett nätverk.
                        </p>
                        <h2 id="about-vision">
                        Varför välja Connectwork?
                        </h2>
                        <p id="about-mission">
                        Vi arbetar kontinuerligt med att hjälpa företag och individer att lyckas över hela Sverige. Vår närvaro är en garant för att dina rekryteringsbehov tillgodoses och för att hjälpa dig att hitta ditt nästa drömjobb. Vårt team förstår att kärnan i varje framgångsrik organisation är dess personal, och att ha högkvalitativa medarbetare är avgörande för att nå excellens. I dagens konkurrensutsatta marknad är det viktigare än någonsin att attrahera och behålla de mest kvalificerade individerna. Vår högt respekterade ledningsgrupp inser hur viktigt det är att kunna rekrytera kvalificerad personal, även under utmanande tidsramar.
                        </p>
                    </div>
                </div>
                <div className="about-image-container">
                    <img src={aboutImage} alt="About Us" className="about-image" />
                </div>
            </div>
        </div>
    );
}

export default About;
