import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../Graphics/NavbarLogo.png';
import './Navbar.css';

const Navbar = ({ loggedIn, handleLogout, setShowLoginPage, setShowJobsPage }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLoginClick = () => {
    setShowLoginPage(true);
    setShowJobsPage(false);
    if (isMobile) setShowMenu(false); // Close the mobile menu when a menu item is clicked
  };

  const handleJobsClick = () => {
    setShowJobsPage(true);
    setShowLoginPage(false);
    if (isMobile) setShowMenu(false); // Close the mobile menu when a menu item is clicked
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    if (isMobile) setShowMenu(false); // Close the mobile menu when a menu item is clicked
  };

  return (
    <nav className={`navbar ${isMobile ? 'mobile' : ''}`}>
      <Link to="/" className="logo">
        <img src={logo} alt="Logo" />
      </Link>
      <div className={`nav-links ${showMenu ? 'show' : ''}`}>
        <Link to="/" onClick={() => {setShowLoginPage(false); setShowJobsPage(false); closeMenu();}}>Hem</Link>
        <Link to="/jobb" onClick={() => {handleJobsClick(); closeMenu();}}>Lediga jobb</Link>
        <a href="#jobbsokande" onClick={() => {setShowLoginPage(false); setShowJobsPage(false); closeMenu();}}>För jobbsökande</a>
        <a href="#ForEmplooyerheaderContainer" onClick={() => {setShowLoginPage(false); setShowJobsPage(false); closeMenu();}}>För arbetsgivare & kunder</a>
        <a href="#om" onClick={() => {setShowLoginPage(false); setShowJobsPage(false); closeMenu();}}>Om oss</a>
        <a href="#kontakt" onClick={() => {setShowLoginPage(false); setShowJobsPage(false); closeMenu();}}>Kontakta oss</a>
        {loggedIn ? (
          <Link to="/" onClick={() => {handleLogout(); closeMenu();}}>Logga ut</Link>
        ) : (
          <Link to="/login" onClick={() => {handleLoginClick(); closeMenu();}}>Logga in</Link>
        )}
      </div>
      {isMobile && (
        <button className={`menu-toggle ${showMenu ? 'active' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      )}
    </nav>
  );
};

export default Navbar;
