import React, { useState, useEffect } from 'react';
import './jobs.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { database } from '../Backend/firebaseConfig';
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';

import { ApplyJobForm } from './ApplyJobForm'; // Import the ApplyJobForm component

function Jobs({ loggedIn }) {
    const [jobs, setJobs] = useState([]);
    const [expandedJobId, setExpandedJobId] = useState(null);
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [editJobId, setEditJobId] = useState(null);
    const [newJobTitle, setNewJobTitle] = useState('');
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newJobDescription, setNewJobDescription] = useState('');
    const [newQualifications, setNewQualifications] = useState('');
    const [newDuration, setNewDuration] = useState('');
    const [newFormOfEmployment, setNewFormOfEmployment] = useState('');
    const [newAboutJob, setNewAboutJob] = useState('');
    const [newAboutCompany, setNewAboutCompany] = useState('');
    const [newSalary, setNewSalary] = useState('');
    const [newLocation, setNewLocation] = useState('');
    const [selectedJobTitle, setSelectedJobTitle] = useState('');
    const [showApplyJobModal, setShowApplyJobModal] = useState(false); // Define showApplyJobModal state variable

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(database, 'jobs'));
            const fetchedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setJobs(fetchedJobs);
        };
        fetchData();
    }, []);

    const toggleExpand = (jobId) => {
        setExpandedJobId(jobId === expandedJobId ? null : jobId);
    };

    const applyJob = (jobId, jobTitle) => {
        setSelectedJobTitle(jobTitle);
        setShowApplyJobModal(true);
    };

    const addJob = async () => {
        setShowAddJobModal(true);
    };

    const saveJob = async () => {
        // Encode newline characters before saving to Firestore
        const encodeNewlines = (text) => text.replace(/\n/g, '\\n');

        const newJobData = {
            title: newJobTitle,
            companyName: newCompanyName,
            description: encodeNewlines(newJobDescription),
            qualifications: encodeNewlines(newQualifications),
            duration: newDuration,
            formOfEmployment: newFormOfEmployment,
            aboutJob: encodeNewlines(newAboutJob),
            aboutCompany: encodeNewlines(newAboutCompany),
            salary: newSalary,
            location: newLocation
        };

        if (editJobId) {
            await updateDoc(doc(database, 'jobs', editJobId), newJobData);
            setEditJobId(null);
        } else {
            await addDoc(collection(database, 'jobs'), newJobData);
        }

        // Clear input fields
        setNewJobTitle('');
        setNewCompanyName('');
        setNewJobDescription('');
        setNewQualifications('');
        setNewDuration('');
        setNewFormOfEmployment('');
        setNewAboutJob('');
        setNewAboutCompany('');
        setNewSalary('');
        setNewLocation('');

        setShowAddJobModal(false);

        // Refresh job list
        const querySnapshot = await getDocs(collection(database, 'jobs'));
        const updatedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setJobs(updatedJobs);
    };

    const editJob = (jobId) => {
        const jobToEdit = jobs.find(job => job.id === jobId);
        setNewJobTitle(jobToEdit.title);
        setNewCompanyName(jobToEdit.companyName);
        setNewJobDescription(decodeNewlines(jobToEdit.description));
        setNewQualifications(decodeNewlines(jobToEdit.qualifications));
        setNewDuration(jobToEdit.duration);
        setNewFormOfEmployment(jobToEdit.formOfEmployment);
        setNewAboutJob(decodeNewlines(jobToEdit.aboutJob));
        setNewAboutCompany(decodeNewlines(jobToEdit.aboutCompany));
        setNewSalary(jobToEdit.salary);
        setNewLocation(jobToEdit.location);
        setEditJobId(jobId);
        setShowAddJobModal(true);
    };
    

    const deleteJob = async (jobId) => {
        await deleteDoc(doc(database, 'jobs', jobId));
        const updatedJobs = jobs.filter(job => job.id !== jobId);
        setJobs(updatedJobs);
    };

    const closeApplyJobModal = () => {
        setShowApplyJobModal(false);
    };

    // Decode newline characters when retrieving data from Firestore
    const decodeNewlines = (text) => text.replace(/\\n/g, '\n');

    return (
        <div className="jobs-container" id="jobb">
            <h2>Lediga jobb</h2>
            <ul className="jobs-list">
                {jobs.map(job => (
                    <li key={job.id} className="job-item">
                        <div className="job-header" onClick={() => toggleExpand(job.id)}>
                            <span>{job.title}</span>
                            <span>{expandedJobId === job.id ? '-' : '+'}</span>
                        </div>
                        {expandedJobId === job.id && (
                            <div className="job-details">
                                <p><strong>Titel:</strong> {job.title}</p>
                                <p><strong>Arbetsgivaren:</strong> {job.companyName}</p>
                                <p><strong>Beskrivning:</strong> <br /> {decodeNewlines(job.description)}</p>
                                <p><strong>Kvalifikationer:</strong> <br /> {decodeNewlines(job.qualifications)}</p>
                                <p><strong>Varaktighet:</strong> {job.duration}</p>
                                <p><strong>Anställningsform:</strong> {job.formOfEmployment}</p>
                                <p><strong>Om jobbet:</strong> <br /> {decodeNewlines(job.aboutJob)}</p>
                                <p><strong>Om företaget:</strong> <br /> {decodeNewlines(job.aboutCompany)}</p>
                                <p><strong>Lön:</strong> {job.salary}</p>
                                <p><strong>Plats:</strong> {job.location}</p>
                                <button id="applyJob" onClick={() => applyJob(job.id, job.title)}>Ansök</button>

                                {loggedIn && (
                                    <div id="container">
                                        <button onClick={() => editJob(job.id)} className="icon-button" id="editJob">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button onClick={() => deleteJob(job.id)} className="icon-button" id="deleteJob">
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            {loggedIn && (
                <div>
                    <button id="addJob" onClick={addJob}><FontAwesomeIcon icon={faPlus}  /></button>
                </div>
            )}
            {showAddJobModal && (
                <div className="modal addJobModal">
                    <div className="modal-content addJobModal-content">
                        <span className="close addJobModal-close" onClick={() => setShowAddJobModal(false)}>&times;</span>
                        <h2>Lägg till jobb</h2>
                        <input
                            id="jobsInput"
                            type="text"
                            placeholder="Jobb titel"
                            value={newJobTitle}
                            onChange={(e) => setNewJobTitle(e.target.value)}
                        />
                        <input
                            id="jobsInput"
                            type="text"
                            placeholder="Arbetsgivaren"
                            value={newCompanyName}
                            onChange={(e) => setNewCompanyName(e.target.value)}
                        />
                        <textarea
                            id="jobsTextarea"
                            className="addJobModal-textarea"
                            placeholder="Jobb beskrivning"
                            value={newJobDescription}
                            onChange={(e) => setNewJobDescription(e.target.value)}
                        />
                        <textarea
                            id="jobsTextarea"
                            className="addJobModal-textarea"
                            placeholder="Kvalifikationer"
                            value={newQualifications}
                            onChange={(e) => setNewQualifications(e.target.value)}
                        />
                        <input
                            id="jobsInput"
                            type="text"
                            placeholder="Varaktighet"
                            value={newDuration}
                            onChange={(e) => setNewDuration(e.target.value)}
                        />
                        <input
                            id="jobsInput"
                            type="text"
                            placeholder="Anställningsform"
                            value={newFormOfEmployment}
                            onChange={(e) => setNewFormOfEmployment(e.target.value)}
                        />
                        <textarea
                            id="jobsTextarea"
                            className="addJobModal-textarea"
                            placeholder="Om jobbet"
                            value={newAboutJob}
                            onChange={(e) => setNewAboutJob(e.target.value)}
                        />
                        <textarea
                            id="jobsTextarea"
                            className="addJobModal-textarea"
                            placeholder="Om företaget"
                            value={newAboutCompany}
                            onChange={(e) => setNewAboutCompany(e.target.value)}
                        />
                        <input
                            id="jobsInput"
                            type="text"
                            placeholder="Lön"
                            value={newSalary}
                            onChange={(e) => setNewSalary(e.target.value)}
                        />
                        <input
                            id="jobsInput"
                            type="text"
                            placeholder="Plats"
                            value={newLocation}
                            onChange={(e) => setNewLocation(e.target.value)}
                        />
                        <button id="jobSaveButton" onClick={saveJob}>{editJobId !== null ? 'Spara' : 'Uppdatera'}</button>
                    </div>
                </div>
            )}
            {showApplyJobModal && (
                <ApplyJobForm
                    jobTitle={selectedJobTitle}
                    onClose={closeApplyJobModal}
                />
            )}
        </div>
    );
}

export default Jobs;
